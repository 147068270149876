import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, navigate }  from 'gatsby'
import { Box } from '@material-ui/core'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Button from '../components/Button'

import H3 from '../components/H3'
import H4 from '../components/H4'
import Paragraph from '../components/Paragraph'
import EstruturaEParceria from '../images/sobre/somos-estrutura-e-parceria.png'

import { mq, Container } from '../components/styles/Layout.styles'
import {
  styledAbout,
  header,
  stylePress,
  contentPress,
  styledBox,
  ImgHeadingBps
} from '../components/styles/About.styles'
import css from '@emotion/css'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <Fragment>
      <section css={styledAbout}>
        <Container>
          <Row
            flexDirection={['column', 'column', 'row', 'row']}
            css={header}
            pb={50}
          >
            <Column
              order={[2, 2, 1, 1]}
              width={[1, 1, 1/2, 1/2]}
              justifyContent={['center', 'center', 'left', 'left']}
              display={['flex']}
              alignItems={['center']}
            >
              <img src={EstruturaEParceria} css={ImgHeadingBps} />
            </Column>
            <Column
              order={[1, 1, 2, 2]}
              ml={[0, 0, 37, 37]}
              width={[1, 1, 1/2, 1/2]}
            >
              <H3>Somos estrutura, parceria e integridade.</H3>
              <Paragraph>A HostDime é uma empresa global que oferece infraestrutura com tecnologia de ponta para a operação de sistemas críticos fundamentais para o funcionamento das organizações. Provemos soluções personalizadas e multi redundantes em conectividade, climatização, segurança física e energia para que o fluxo de dados mantenha-se disponível e veloz.</Paragraph>
              <Paragraph>Atualmente, a empresa está entre os 3 maiores provedores de data center do mundo, presente em oito países e, há 13 anos, atua no Brasil operando data centers certificados em São Paulo e no Nordeste.</Paragraph>
            </Column>
          </Row>
        </Container>
      </section>
      <section>
        <Container
          css={css(
            mq({
              padding: [0, 0, 'initial'],
              margin: [0, 0, 'initial'],
              maxWidth: ['100%', '100%', 'initial']
            })
          )}
        >
          <Box>
            <Row
              flexDirection={['column', 'column', 'row']}
              mx={null}
            >
              <Column
                pt={[50]}
                pb={[50]}
                pl={[35, 90, 62, 73]}
                pr={[35, 90, 62, 73]}
                px={null}
                width={[1, 1, 1/2, 1/2]}
                style={{
                  backgroundColor: '#f3f5f5'
                }}
                css={styledBox}
              >
                <H4>
                  <span>CULTURA</span>
                  Não somos apaixonados apenas por tecnologia, somos apaixonados por desenvolver as pessoas.
                </H4>
                <Button
                  onClick={() => navigate('/cultura')}
                  outlined
                  schema='lightPeriwinkle'
                  align='center'
                  style={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    fontWeight: 600,
                    letterSpacing: 'normal',
                    lineHeight: 1.43,
                    padding: '12px 16px 12px',
                    width: 150,
                    outline: 'none',
                    border: 'none',
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
                    backgroundColor: '#ffffff',
                    marginTop: 30
                  }}
                >
                  NOSSO DNA
                </Button>
              </Column>
              <Column
                pt={[50]}
                pb={[50]}
                pl={[35, 90, 62, 73]}
                pr={[35, 90, 62, 73]}
                px={null}
                width={[1, 1, 1/2, 1/2]}
                style={{
                  backgroundColor: '#f9fafa',
                }}
                css={styledBox}
              >
                <H4>
                  <span>CARREIRAS</span>
                  Evoluímos com paixão.
                </H4>
                <Paragraph>Somos apaixonados por tecnologia e acreditamos que podemos usá-la para transformar a vida das pessoas.</Paragraph>
                <Button
                  onClick={() => navigate('/carreiras')}
                  outlined
                  schema='lightPeriwinkle'
                  align='center'
                  style={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    fontWeight: 600,
                    letterSpacing: 'normal',
                    lineHeight: 1.43,
                    padding: '12px 16px 12px',
                    width: 280,
                    outline: 'none',
                    border: 'none',
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
                    backgroundColor: '#ffffff'
                  }}
                >
                  VENHA PARA A HOSTDIME
                </Button>
              </Column>
            </Row>
          </Box>
        </Container>
      </section>
      <section>
        <Box 
          css={stylePress}
        >
          <Container
            css={css(
              mq({
                paddingLeft: [35, 90, 0, 0],
                paddingRight: [35, 90, 0, 0],
                margin: [0],
                maxWidth: ['100%']
              })
            )}
          >
            <Row
              flexDirection={['column', 'column', 'row', 'row']}
              css={contentPress}
              pt={[50, 50, 80, 97]}
              pb={[60, 50, 100, 117]}
            >
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <H3>
                  <span>IMPRENSA</span>
                  A nossa marca é única.
                </H3>
                <Paragraph>Organização, agilidade, tecnologia e flexibilidade não são apenas nossos pilares. São nosso DNA. E essa essência também está impressa em nossa identidade e em nossa marca.</Paragraph>
              </Column>
              <Column
                mt={[30, 30, 0, 0]}
                width={[1, 1, 1/2, 1/2]}
              >
                <Box
                  mt={[0, 0, 6, 6]}
                  ml={[0, 0, 0, 10]}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <Button
                    onClick={() => navigate('/nossa-jornada')}
                    align='center'
                    style={{
                      fontWeight: 600,
                      letterSpacing: 1.25,
                      lineHeight: 1.16,
                      padding: '15px 16px 14px 16px',
                      width: 200,
                      color: '#f9fafa',
                      outline: 'none',
                      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
                      marginBottom: 16
                    }}
                  >
                    NOSSA JORNADA
                  </Button>
                  <Button
                    onClick={() => navigate('/press-kit')}
                    outlined
                    schema='lightPeriwinkle'
                    align='center'
                    style={{
                      color: '#666666',
                      fontWeight: 600,
                      letterSpacing: 'normal',
                      lineHeight: 1.43,
                      padding: '12px 16px 12px',
                      width: 290,
                      outline: 'none',
                      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
                      backgroundColor: '#fafafa'
                    }}
                  >
                    PRESS KIT GUIA DE MARCA
                  </Button>
                </Box>
              </Column>
            </Row>
          </Container>
        </Box>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Sobre' />
      <PageTemplate
        title='Sobre'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 390) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage