import { mq } from './Layout.styles'
import styled from '@emotion/styled'
import Atom from './Atom.styles'

/* Default sizes change if you need */
export const StyledH3 = styled(Atom)(props => (
  mq({
    fontWeight: ['normal'],
    letterSpacing: [0.25],
    lineHeight: ['normal']
  })
))
