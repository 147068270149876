import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledH3
} from './styles/H3.styles'

const H3 = ({ children, ...props }) => (
  <StyledH3
    as='h3'
    {...props}
  >
    {children}
  </StyledH3>
)

H3.propTypes = {
  children: PropTypes.node.isRequired
}

H3.defaultProps = {
  fontSize: [47.9]
}

export default H3
